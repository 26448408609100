import React, { FC } from 'react';
import { Link } from 'gatsby';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';
import { IHeroBanner } from './models';
import './styles.scss';

export const HeroBannerItem: FC<IHeroBanner> = ({
  boldTitle,
  descriptionText,
  buttonText,
  buttonURL: [{ url }],
  image,
  packshotImageAlt,
  productImage,
  packshotPosition,
}) => {
  const [position] = packshotPosition;

  return (
    <div className="hero-banner-item" style={{ backgroundImage: `url(${image?.url})` }}>
      <Container
        className={classNames('hero-banner-item__content-container', {
          revert: position === 'left',
        })}
      >
        <div className={classNames('hero-banner-item__content', position)}>
          <h2 className="hero-banner-item__title">{boldTitle}</h2>
          <p className="hero-banner-item__description">{descriptionText}</p>
          <Link to={url} className="hero-banner-item__link">
            {buttonText}
            <i className="left-chevron-icon" />
          </Link>
        </div>
        <div className="hero-banner-item__packshot">
          <div className={classNames('hero-banner-item__packshot-holder', position)}>
            <UmbracoImage
              image={productImage}
              alt={packshotImageAlt}
              className="hero-banner-item__packshot-item"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroBannerItem;
